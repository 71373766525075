import React from 'react';
import {TextField, RadioGroup, FormControlLabel, Radio, Paper, Typography, Divider, Button, Drawer} from '@mui/material';

export default function WdSidePanel() {
	const [state, setState] = React.useState({right: false});
	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
		if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
			return;
		}
		setState({ ...state, ['right']: open });
	};

	return (
		<div>
			<React.Fragment key={'right'}>
			<Button onClick={toggleDrawer(true)} sx={{float:'right', m:3}}>Open Side Panel</Button>
			<Drawer
				anchor={'right'}
				open={state['right']}
				onClose={toggleDrawer(false)}>
				<Paper sx={{p:3}} elevation={0}>
					<Typography variant="h6"  sx={{width:500}}>Send Email</Typography> 
					<Divider /><br />
					<Typography>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Typography><br />
					<TextField id="standard-basic" label="To:" variant="standard"  fullWidth /><br />
					<TextField id="standard-basic" label="Cc:" variant="standard" fullWidth /><br />
					<TextField id="standard-basic" label="Bcc:" variant="standard" fullWidth /><br /><br />
					<TextField fullWidth label="Message:" multiline rows={8} variant="filled"  /><br />
					<Divider /><br />
					<RadioGroup	 aria-labelledby="demo-radio-buttons-group-label" defaultValue="1" name="radio-buttons-group">
						<FormControlLabel value="0" control={<Radio />} label="Ticket Subscription E-mail" />
						<FormControlLabel value="1" control={<Radio />} label="New Incident E-mail" />
						<FormControlLabel value="2" control={<Radio />} label="New Incident E-mail (2)" />
						<FormControlLabel value="3" control={<Radio />} label="New Incident E-mail (3)" />
						<FormControlLabel value="4" control={<Radio />} label="New Incident E-mail (4)" />
						<FormControlLabel value="5" control={<Radio />} label="New Incident E-mail (5)" />
					</RadioGroup>

				</Paper>
			</Drawer>
			</React.Fragment>
		</div>
	);
}