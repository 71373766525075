import React from 'react';
import WdHeader from './components/WdHeader';
import WdToolbar from './components/WdToolbar';
import WdCanvas from './components/WdCanvas';
import WdSidePanel from './components/WdSidePanel';
// import MyClassComponent from './components/MyClassComponent';

export default function App() {
	const [counter, setCounter] = React.useState(0);
	return (
		<div className="wd-app">
			<WdHeader />
			<WdToolbar />
			<WdCanvas />
			<WdSidePanel />
			{/*
			<Button variant="contained" color="primary" onClick={() => { setCounter(counter+1); }} > {counter} (+1)</Button>
			<MyClassComponent resetTopCounter={setCounter} decreaseCount={() => {setCounter(counter - 1)}} />
			*/}
		</div>
	);
}