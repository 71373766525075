import { IconButton, Divider, Switch, Toolbar, Typography, Paper} from '@mui/material';
import {
		SaveOutlined			as IconSave,
		Undo					as IconUndo,
		Redo					as IconRedo,
		Restore					as IconRestore,
		FilterNoneOutlined		as IconGroup,
		DeleteOutlineOutlined	as IconDelete

} from '@mui/icons-material';

export default function WdToolbar() {
	return (
		<Paper elevation={4}>
			<Toolbar variant="dense">
				<Typography variant="caption" noWrap sx={{ flexGrow: 1 }}>current instance: demo.m42cloud.com</Typography> 
				<Typography variant="caption" noWrap sx={{ flexGrow: 1 }}>name: ticket subscription</Typography> 
				<Divider flexItem orientation="vertical" sx={{mx:1}} />
				<IconButton><IconSave /></IconButton>
				<Divider flexItem orientation="vertical" sx={{mx:1}} />
				<Switch />
				<Typography variant="caption">Not Published</Typography> 
				<Divider flexItem orientation="vertical" sx={{mx:1}} />
				<IconButton><IconUndo /></IconButton>
				<IconButton><IconRedo /></IconButton>
				<IconButton><IconRestore /></IconButton>
				<Divider flexItem orientation="vertical" sx={{mx:1}} />
				<IconButton><IconGroup /></IconButton>
				<IconButton><IconDelete /></IconButton>
			</Toolbar>
		</Paper>
	);
}
