import {Toolbar, Typography, Paper, IconButton, Divider, Chip} from '@mui/material';
import {
	PlayCircle					as IconRun,
	FileDownloadOutlined		as IconGet,
	Send						as IconSend,
	ArrowDownwardOutlined		as IconArrowDown,
	ArrowForwardOutlined		as IconArrowRight,
	CallSplit					as IconIf,
	AddCircle					as IconAdd,
	MoreVert					as IconMore,
} from '@mui/icons-material';

export default function WdCanvas() {
	const flowModules = [
		{type: 'trigger',	label: 'When a ... ... is ...'},
		{type: 'get',	label: 'Get ... ...', },
		{type: 'if',	label: 'Conditional ... ... is ...',},
		{type: 'send',	label: 'Send ... to ...',},
		{type: 'get',	label: 'Get ... ...', },
		{type: 'if',	label: 'Conditional ... ... is ...',},
		{type: 'send',	label: 'Send ... to ...',},
		{type: 'get',	label: 'Get ... ...', },
		{type: 'if',	label: 'Conditional ... ... is ...',},
		{type: 'send',	label: 'Send ... to ...',},
	];
	return (
		<div style={{position: 'absolute', overflow: 'scroll', top:120, left:0, right:0, bottom:0}}>
			{
				flowModules.map((item, i) => (
				<div style={{position:'absolute', top:40+i*100, left:50, width:500}}>
					<Paper elevation={4}>
						<Toolbar disableGutters>
							{item.type === 'trigger'	&& <IconRun sx={{color:'#64c554', background:'#dffbe5', fontSize: 40, ml:1}}  /> }
							{item.type === 'get'		&& <IconGet sx={{color:'#fff', background:'#335fd1', fontSize: 40, ml:1}}  /> }
							{item.type === 'if'			&& <IconIf sx={{color:'#fff', background:'#ef4f62', fontSize: 40, ml:1, transform: "rotate(-180deg)"}} /> }
							{item.type === 'send'		&& <IconSend sx={{color:'#fff', background:'#335fd1', fontSize: 40, ml:1}} /> }
							<Divider flexItem orientation="vertical" sx={{mx:1}} />
							<Typography variant="caption" noWrap sx={{ flexGrow: 1 }}>{item.label}</Typography> 
							<IconButton color="primary"><IconAdd /></IconButton>
							<IconButton><IconMore /></IconButton>
						</Toolbar>
					</Paper>

					<div style={{margin:'6px 0 0 50%'}}>{item.type === 'if' ? <Chip icon={<IconArrowDown />} label='then'  size="small" /> : <IconArrowDown />}</div>
					{item.type === 'if' && (
						<div style={{margin:'-73px 0 0 calc(100% + 10px)', display:'flex'}}>
							<Chip icon={<IconArrowRight />} label='else...' size="small" />
							<IconButton color="primary" style={{marginTop:'-13px'}}><IconAdd fontSize="large" /></IconButton>
						</div>
					)}

					{flowModules.length-1 === i && (
						<IconButton color="primary" style={{margin:'0 0 50% calc(50% - 14px)'}}><IconAdd fontSize="large" /></IconButton>
					)}
					
				</div>))
			}

		</div>
	);
}
