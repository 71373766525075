import {AppBar, Toolbar, Typography, Avatar}  from '@mui/material';
import logo from '../logo.png';

export default function WdHeader() {
	return (
		<AppBar position="static" color="inherit">
			<Toolbar>
				<img src={logo} className="App-logo" alt="logo" />
				<Typography variant="h6" component="h6" sx={{ ml:'20px', color:'#AAA', flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
					PROTOTYPE
				</Typography> 
				<Avatar />
			</Toolbar>
		</AppBar>
	);
}
